/**
 * Do not edit directly
 * Generated on Thu, 30 May 2024 04:56:27 GMT
 */

:root {
  --dss-background-button-fill-primary: #4e46b4;
  --dss-background-button-fill-hovered: #4740a4;
  --dss-background-button-fill-pressed: #4740a4;
  --dss-background-button-fill-disabled: #17171740;
  --dss-background-button-danger-primary: #DC4440;
  --dss-background-button-danger-hovered: #D31510;
  --dss-background-button-danger-pressed: #D31510;
  --dss-background-button-danger-disabled: #17171740;
  --dss-background-button-default-primary: #E0DFF5;
  --dss-background-button-default-hovered: #c8c6e8;
  --dss-background-button-default-pressed: #c8c6e8;
  --dss-background-button-default-disabled: #1717170d;
  --dss-background-button-ghost-primary: #4e46b4;
  --dss-background-button-ghost-hovered: #E0DFF5;
  --dss-background-button-ghost-pressed: #E0DFF5;
  --dss-background-button-ghost-icon-fill: #4e46b4;
  --dss-background-button-stoke-hovered: #E0DFF5;
  --dss-background-button-stoke-pressed: #E0DFF5;
  --dss-background-surface: #ffffff;
  --dss-background-surface-hover: #E0DFF5;
  --dss-background-negative: #D31510;
  --dss-background-disabled: #17171708;
  --dss-background-caution: #F26A2F;
  --dss-background-positive: #0A8A00;
  --dss-background-informative: #0058A3;
  --dss-background-inverse: #171717;
  --dss-background-primary: #EFEFEF;
  --dss-background-secondary: #c2c2c2;
  --dss-background-tertiary: #A3A3A3;
  --dss-background-selected: #4e46b4;
  --dss-background-on-bg-hover: #ffffff26;
  --dss-background-info: #171717;
  --dss-background-scrollbar: #c2c2c2;
  --dss-background-default: #A3A3A3;
  --dss-background-default-hover: #6d6d6d;
  --dss-background-fill-caution: #FEF0EA;
  --dss-background-fill-informative: #E6EEF6;
  --dss-background-fill-positive: #E7F3E6;
  --dss-background-fill-negative-secondary-hover: #F9BA9F;
  --dss-background-fill-negative-secondary: #FBD1BF;
  --dss-background-fill-positive-secondary-hover: #8EC98A;
  --dss-background-fill-positive-secondary: #B3DBB0;
  --dss-background-fill-default: #EFEFEF;
  --dss-background-fill-default-hover: #c2c2c2;
  --dss-background-fill-transparent-hover: #ffffff80;
  --dss-background-emphasis: #4740a4;
  --dss-background-discovery: #E0DFF5;
  --dss-background-surface-informative: #8AB2D5;
  --dss-background-surface-caution: #F9BA9F;
  --dss-background-surface-success: #B3DBB0;
  --dss-background-surface-danger: #EB9391;
  --dss-border-button-stroke-primary: #4e46b4;
  --dss-border-button-stroke-hovered: #4740a4;
  --dss-border-button-stroke-pressed: #4740a4;
  --dss-border-button-stroke-disabled: #17171740;
  --dss-border-primary: #c2c2c2;
  --dss-border-light: #EFEFEF;
  --dss-border-secondary: #A3A3A3;
  --dss-border-tertiary: #6d6d6d;
  --dss-border-negative: #D31510;
  --dss-border-disabled: #EFEFEF;
  --dss-border-caution: #F26A2F;
  --dss-border-positive: #0A8A00;
  --dss-border-informative: #0058A3;
  --dss-border-selected: #4e46b4;
  --dss-border-input: #A3A3A3;
  --dss-border-border-inverse: #ffffff;
  --dss-text-button-fill-primary: #ffffff;
  --dss-text-button-stroke-primary: #4e46b4;
  --dss-text-button-stroke-disabled: #17171740;
  --dss-text-button-default-primary: #4e46b4;
  --dss-text-button-default-disabled: #17171740;
  --dss-text-button-danger-primary: #ffffff;
  --dss-text-button-ghost-primary: #4e46b4;
  --dss-text-button-ghost-disabled: #17171740;
  --dss-text-button-link-primary: #4e46b4;
  --dss-text-button-link-hovered: #4740a4;
  --dss-text-button-link-pressed: #4740a4;
  --dss-text-button-link-disabled: #17171740;
  --dss-text-button-link-negative: #DC4440;
  --dss-text-primary: #171717;
  --dss-text-disabled: #17171740;
  --dss-text-secondary: #434343;
  --dss-text-tertiary: #6d6d6d;
  --dss-text-placeholder: #c2c2c2;
  --dss-text-negative: #D31510;
  --dss-text-caution: #F26A2F;
  --dss-text-positive: #0A8A00;
  --dss-text-informative: #0058A3;
  --dss-text-on-bg-fill: #ffffff;
  --dss-text-on-bg-fill-secondary: #c2c2c2;
  --dss-text-selected: #4e46b4;
  --dss-text-on-bg-fill-transparent-hover: #ffffff80;
  --dss-icon-primary: #171717;
  --dss-icon-selected: #4e46b4;
  --dss-icon-brand: #4e46b4;
  --dss-primary-100: #E0DFF5;
  --dss-primary-200: #c8c6e8;
  --dss-primary-300: #aeaadd;
  --dss-primary-400: #8883cd;
  --dss-primary-500: #716bc3;
  --dss-primary-600: #4e46b4;
  --dss-primary-700: #4740a4;
  --dss-primary-800: #373280;
  --dss-primary-900: #2b2763;
  --dss-primary-1000: #211d4c;
  --dss-supporting-color1-100: #fdf7ea;
  --dss-supporting-color1-200: #f8e6bf;
  --dss-supporting-color1-300: #f5d9a0;
  --dss-supporting-color1-400: #f0c875;
  --dss-supporting-color1-500: #edbd5a;
  --dss-supporting-color1-600: #e9ad31;
  --dss-supporting-color1-700: #d49d2d;
  --dss-supporting-color1-800: #a57b23;
  --dss-supporting-color1-900: #805f1b;
  --dss-supporting-color1-1000: #624915;
  --dss-supporting-color2-100: #eaf8f3;
  --dss-supporting-color2-200: #bee8d9;
  --dss-supporting-color2-300: #9eddc7;
  --dss-supporting-color2-400: #72cdad;
  --dss-supporting-color2-500: #57c49d;
  --dss-supporting-color2-600: #2db585;
  --dss-supporting-color2-700: #29a579;
  --dss-supporting-color2-800: #20815e;
  --dss-supporting-color2-900: #196449;
  --dss-supporting-color2-1000: #134c38;
  --dss-supporting-color3-100: #eaf1fa;
  --dss-supporting-color3-200: #bdd4ee;
  --dss-supporting-color3-300: #9dc0e6;
  --dss-supporting-color3-400: #70a3da;
  --dss-supporting-color3-500: #5591d3;
  --dss-supporting-color3-600: #2a75c8;
  --dss-supporting-color3-700: #266ab6;
  --dss-supporting-color3-800: #1e538e;
  --dss-supporting-color3-900: #17406E;
  --dss-supporting-color3-1000: #123154;
  --dss-secondary-100: #ffece6;
  --dss-secondary-200: #ffc5b0;
  --dss-secondary-300: #ffa98a;
  --dss-secondary-400: #ff8254;
  --dss-secondary-500: #ff6933;
  --dss-secondary-600: #ff4400;
  --dss-secondary-700: #e83e00;
  --dss-secondary-800: #b53000;
  --dss-secondary-900: #8c2500;
  --dss-secondary-1000: #6b1d00;
  --dss-neutrals-100: #ffffff;
  --dss-neutrals-200: #EFEFEF;
  --dss-neutrals-300: #c2c2c2;
  --dss-neutrals-400: #A3A3A3;
  --dss-neutrals-500: #6d6d6d;
  --dss-neutrals-600: #585858;
  --dss-neutrals-700: #434343;
  --dss-neutrals-800: #353535;
  --dss-neutrals-900: #1f1f1f;
  --dss-neutrals-1000: #171717;
  --dss-shadow-0: 0px 0px 0px 0px #171717;
  --dss-shadow-100: 0px 1px 0px 0px #17171780;
  --dss-shadow-200: 0px 3px 1px -1px #17171780;
  --dss-shadow-300: 0px 4px 6px -2px #17171780;
  --dss-shadow-400: 0px 8px 16px -4px #17171780;
  --dss-shadow-500: 0px 12px 20px -8px #17171780;
  --dss-shadow-600: 0px 20px 20px -8px #17171780;
  --dss-shadow-fill-pressed-extra-large: 0px 0px 0px 6px #c8c6e8;
  --dss-shadow-fill-pressed-large: 0px 0px 0px 5px #c8c6e8;
  --dss-shadow-fill-pressed-normal: 0px 0px 0px 3px #c8c6e8;
  --dss-shadow-fill-pressed-dense: 0px 0px 0px 3px #c8c6e8;
  --dss-shadow-fill-pressed-denser: 0px 0px 0px 3px #c8c6e8;
  --dss-shadow-default-pressed-extra-large: 0px 0px 0px 6px #E0DFF5;
  --dss-shadow-default-pressed-large: 0px 0px 0px 5px #E0DFF5;
  --dss-shadow-default-pressed-normal: 0px 0px 0px 3px #E0DFF5;
  --dss-shadow-default-pressed-dense: 0px 0px 0px 3px #E0DFF5;
  --dss-shadow-default-pressed-denser: 0px 0px 0px 3px #E0DFF5;
  --dss-shadow-danger-pressed-extra-large: 0px 0px 0px 6px #F1B6B5;
  --dss-shadow-danger-pressed-large: 0px 0px 0px 5px #F1B6B5;
  --dss-shadow-danger-pressed-normal: 0px 0px 0px 3px #F1B6B5;
  --dss-shadow-danger-pressed-dense: 0px 0px 0px 3px #F1B6B5;
  --dss-shadow-danger-pressed-denser: 0px 0px 0px 3px #F1B6B5;
  --dss-shadow-stroke-pressed-extra-large: 0px 0px 0px 6px #E0DFF5;
  --dss-shadow-stroke-pressed-large: 0px 0px 0px 5px #E0DFF5;
  --dss-shadow-stroke-pressed-normal: 0px 0px 0px 3px #E0DFF5;
  --dss-shadow-stroke-pressed-dense: 0px 0px 0px 3px #E0DFF5;
  --dss-shadow-stroke-pressed-denser: 0px 0px 0px 3px #E0DFF5;
  --dss-shadow-ghost-pressed-extra-large: 0px 0px 0px 6px #c8c6e8;
  --dss-shadow-ghost-pressed-large: 0px 0px 0px 5px #c8c6e8;
  --dss-shadow-ghost-pressed-normal: 0px 0px 0px 3px #c8c6e8;
  --dss-shadow-ghost-pressed-dense: 0px 0px 0px 3px #c8c6e8;
  --dss-shadow-ghost-pressed-denser: 0px 0px 0px 3px #c8c6e8;
  --dss-font-family-primary: Inter;
  --dss-font-family-secondary: Inter;
  --dss-line-height-90: 90%;
  --dss-line-height-100: 100%;
  --dss-line-height-120: 120%;
  --dss-line-height-130: 130%;
  --dss-line-height-140: 140%;
  --dss-line-height-150: 150%;
  --dss-font-weight-regular: 400;
  --dss-font-weight-medium: 500;
  --dss-font-weight-semi-bold: 600;
  --dss-font-weight-bold: 700;
  --dss-font-size-100: 11px;
  --dss-font-size-200: 13px;
  --dss-font-size-300: 14px;
  --dss-font-size-400: 16px;
  --dss-font-size-500: 18px;
  --dss-font-size-600: 20px;
  --dss-font-size-700: 23px;
  --dss-font-size-800: 26px;
  --dss-font-size-900: 29px;
  --dss-font-size-1000: 32px;
  --dss-font-letter-spacing-normal: 0px;
  --dss-font-letter-spacing-dense: -0.2px;
  --dss-font-letter-spacing-denser: -0.3px;
  --dss-font-letter-spacing-densest: -0.54px;
  --dss-text-case-none: none;
  --dss-text-case-uppercase: uppercase;
  --dss-paragraph-spacing-0: 0px;
  --dss-paragraph-spacing-100: 8px;
  --dss-paragraph-spacing-150: 12px;
  --dss-paragraph-spacing-200: 16px;
  --dss-text-decoration-underline: underline;
  --dss-text-decoration-none: none;
  --dss-text-decoration-strike-through: line-through;
  --dss-border-radius-0: 0px;
  --dss-border-radius-50: 2px;
  --dss-border-radius-100: 4px;
  --dss-border-radius-150: 6px;
  --dss-border-radius-200: 8px;
  --dss-border-radius-300: 12px;
  --dss-border-radius-400: 16px;
  --dss-border-radius-500: 20px;
  --dss-border-radius-700: 28px;
  --dss-border-radius-full: 9999px;
  --dss-space-0: 0px;
  --dss-space-25: 1px;
  --dss-space-50: 2px;
  --dss-space-100: 4px;
  --dss-space-150: 6px;
  --dss-space-200: 8px;
  --dss-space-250: 10px;
  --dss-space-300: 12px;
  --dss-space-400: 16px;
  --dss-space-500: 20px;
  --dss-space-600: 24px;
  --dss-space-800: 32px;
  --dss-space-1000: 40px;
  --dss-space-1200: 48px;
  --dss-space-1400: 56px;
  --dss-space-1600: 64px;
  --dss-space-2000: 80px;
  --dss-space-2400: 96px;
  --dss-space-2800: 112px;
  --dss-space-3200: 128px;
  --dss-space-button-extra-large: 16px 24px;
  --dss-space-button-large: 12px 20px;
  --dss-space-button-normal: 12px 16px;
  --dss-space-button-dense: 8px 12px;
  --dss-space-button-denser: 4px 8px;
  --dss-ui-text-lg-regular: [object Object];
  --dss-ui-text-lg-medium: [object Object];
  --dss-ui-text-lg-semi-bold: [object Object];
  --dss-ui-text-md-regular: [object Object];
  --dss-ui-text-md-medium: [object Object];
  --dss-ui-text-md-semi-bold: [object Object];
  --dss-ui-text-sm-regular: [object Object];
  --dss-ui-text-sm-medium: [object Object];
  --dss-ui-text-sm-semi-bold: [object Object];
  --dss-ui-text-xs-regular: [object Object];
  --dss-ui-text-xs-medium: [object Object];
  --dss-ui-text-xs-semi-bold: [object Object];
  --dss-ui-text-xxs-regular: [object Object];
  --dss-ui-text-xxs-medium: [object Object];
  --dss-ui-text-xxs-semi-bold: [object Object];
  --dss-body-lg: [object Object];
  --dss-body-md: [object Object];
  --dss-body-sm: [object Object];
  --dss-body-compact-md: [object Object];
  --dss-body-compact-sm: [object Object];
  --dss-body-strong-sm-medium: [object Object];
  --dss-body-strong-sm-semi-bold: [object Object];
  --dss-body-strong-md-medium: [object Object];
  --dss-body-strong-md-semi-bold: [object Object];
  --dss-body-strong-lg-medium: [object Object];
  --dss-body-strong-lg-semi-bold: [object Object];
  --dss-heading-3xl: [object Object];
  --dss-heading-2xl: [object Object];
  --dss-heading-xl: [object Object];
  --dss-heading-lg: [object Object];
  --dss-heading-md: [object Object];
  --dss-heading-sm: [object Object];
  --dss-heading-xs: [object Object];
  --dss-heading-xxs: [object Object];
  --dss-border-width-0: 0px;
  --dss-border-width-50: 0.5px;
  --dss-border-width-100: 1px;
  --dss-border-width-150: 1.5px;
  --dss-border-width-200: 2px;
  --dss-border-width-250: 2.5px;
  --dss-border-width-300: 3px;
  --dss-border-width-400: 4px;
  --dss-border-width-500: 5px;
  --dss-size-25: 1px;
  --dss-size-50: 2px;
  --dss-size-100: 4px;
  --dss-size-200: 8px;
  --dss-size-300: 12px;
  --dss-size-400: 16px;
  --dss-size-500: 20px;
  --dss-size-600: 24px;
  --dss-size-650: 28px;
  --dss-size-800: 32px;
  --dss-size-1000: 40px;
  --dss-size-1200: 48px;
  --dss-size-1400: 56px;
  --dss-size-1600: 64px;
  --dss-size-1800: 72px;
  --dss-size-2000: 80px;
  --dss-size-2400: 96px;
  --dss-size-2500: 100px;
  --dss-size-2600: 104px;
  --dss-size-2800: 112px;
  --dss-size-3200: 128px;
  --dss-size-8200: 328px;
  --dss-alpha-neutrals-100-a: #17171708;
  --dss-alpha-neutrals-200-a: #1717170d;
  --dss-alpha-neutrals-300-a: #17171726;
  --dss-alpha-neutrals-400-a: #17171740;
  --dss-alpha-neutrals-500-a: #17171780;
  --dss-alpha-neutrals-0-a: #ffffff00;
  --dss-alpha-semantic-200-a: #e0075126;
  --dss-alpha-dark-neutral-100-a: #ffffff80;
  --dss-alpha-dark-neutral-200-a: #ffffff40;
  --dss-alpha-dark-neutral-300-a: #ffffff26;
  --dss-alpha-dark-neutral-400-a: #ffffff0d;
  --dss-alpha-dark-neutral-500-a: #ffffff08;
  --dss-semantics-negative-100: #FBE8E7;
  --dss-semantics-negative-200: #F1B6B5;
  --dss-semantics-negative-300: #EB9391;
  --dss-semantics-negative-400: #E2625F;
  --dss-semantics-negative-500: #DC4440;
  --dss-semantics-negative-600: #D31510;
  --dss-semantics-negative-700: #C0130F;
  --dss-semantics-negative-800: #960F0B;
  --dss-semantics-negative-900: #740C09;
  --dss-semantics-negative-1000: #590907;
  --dss-semantics-caution-100: #FEF0EA;
  --dss-semantics-caution-200: #FBD1BF;
  --dss-semantics-caution-300: #F9BA9F;
  --dss-semantics-caution-400: #F69B74;
  --dss-semantics-caution-500: #F58859;
  --dss-semantics-caution-600: #F26A2F;
  --dss-semantics-caution-700: #DC602B;
  --dss-semantics-caution-800: #AC4B21;
  --dss-semantics-caution-900: #853A1A;
  --dss-semantics-caution-1000: #662D14;
  --dss-semantics-positive-100: #E7F3E6;
  --dss-semantics-positive-200: #B3DBB0;
  --dss-semantics-positive-300: #8EC98A;
  --dss-semantics-positive-400: #5BB154;
  --dss-semantics-positive-500: #3BA133;
  --dss-semantics-positive-600: #0A8A00;
  --dss-semantics-positive-700: #097E00;
  --dss-semantics-positive-800: #076200;
  --dss-semantics-positive-900: #064C00;
  --dss-semantics-positive-1000: #043A00;
  --dss-semantics-informative-100: #E6EEF6;
  --dss-semantics-informative-200: #B0CBE2;
  --dss-semantics-informative-300: #8AB2D5;
  --dss-semantics-informative-400: #548FC1;
  --dss-semantics-informative-500: #3379B5;
  --dss-semantics-informative-600: #0058A3;
  --dss-semantics-informative-700: #005094;
  --dss-semantics-informative-800: #003E74;
  --dss-semantics-informative-900: #00345F;
  --dss-semantics-informative-1000: #002544;
  --dss-token-set-order-0: core;
  --dss-token-set-order-1: light;
}
