.radio-container {
  --_radio-border-color: var(--dss-background-tertiary);
  --_radio-border-color-hovered: var(--dss-background-selected);
  --_radio-border-color-active: var(--dss-background-selected);
  --_radio-border-color-disabled: var(--dss-background-disabled);
  --_radio-size: var(--dss-space-500);
  --_radio-dot-size: var(--dss-space-300);
  --_radio-dot-color-active: var(--dss-background-surface);
  --_radio-border-radius: var(--dss-border-radius-full);
  --_radio-label-spacing: var(--dss-space-200);
  --_radio-label-color: var(--dss-text-primary);
  --_radio-label-font-weight: var(--dss-font-weight-regular);
  --_radio-label-font-size: var(--dss-font-size-400);
  display: flex;
  align-items: center;
  gap: var(--_radio-label-spacing);

  .radio {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: var(--_radio-size);
    height: var(--_radio-size);
    border-radius: var(--_radio-border-radius);
    border: 2px solid var(--_radio-border-color);
    outline: none;
    cursor: pointer;
    position: relative;
    transition: all 0.4s ease;

    &:hover {
      --_radio-border-color: var(--_radio-border-color-hovered);
    }

    &:checked {
      --_radio-border-color: var(--_radio-border-color-active);
      --_radio-dot-color-active: var(--dss-background-selected);
    }

    &-default::before {
      content: "";
      width: var(--_radio-dot-size);
      height: var(--_radio-dot-size);
      background-color: var(--_radio-dot-color-active);
      border-radius: var(--_radio-border-radius);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &-label {
      cursor: pointer;
      color: var(--_radio-label-color);
      font-size: var(--_radio-label-font-size);
      font-weight: var(--_radio-label-font-weight);
    }

    &-disabled {
      --_radio-border-color: var(--dss-background-disabled);
      --_radio-border-color-hovered: var(--dss-background-disabled);

      &:checked {
        --_radio-border-color: var(--dss-background-disabled);
        --_radio-dot-color-active: var(--dss-background-disabled);
      }
    }

    // &-large {
    //   --_radio-size: var(--dss-space-500);
    //   --_radio-dot-size: var(--dss-space-300);
    // }

    &-small {
      --_radio-size: var(--dss-space-400);
      --_radio-dot-size: var(--dss-space-200);
    }
  }
}