.toggle {
  --_toggle-width: var(--dss-space-800);
  --_toggle-height: var(--dss-space-400);
  --_toggle-button-size: var(--dss-space-300);
  --_toggle-radius: var(--dss-border-radius-full);
  --_toggle-padding: var(--dss-space-50);
  --_toggle-bg: var(--dss-background-tertiary);
  --_toggle-button-bg: var(--dss-background-surface);
  --_toggle-active-translateX: 15px;

  position: relative;
  display: inline-block;
  width: var(--_toggle-width);
  height: var(--_toggle-height);

  &:hover {
    --_toggle-bg: var(--dss-background-default-hover);
  }

  &-active {
    --_toggle-bg: var(--dss-background-selected) !important;
  }

  &-disable {
    --_toggle-bg: var(--dss-background-disabled) !important;
    pointer-events: none;
  }

  &-label {
    display: block;
    width: 100%;
    height: 100%;
    background-color: var(--_toggle-bg);
    border-radius: var(--_toggle-radius);
    position: relative;
    cursor: pointer;
    transition: background .4s;
  }

  &-slider {
    position: absolute;
    content: '';
    height: var(--_toggle-button-size);
    width: var(--_toggle-button-size);
    left: var(--_toggle-padding);
    bottom: var(--_toggle-padding);
    background-color: var(--_toggle-button-bg);
    border-radius: var(--_toggle-radius);
    transition: transform .4s;
  }

  &-checkbox {
    display: none;

    &:checked {
      &+.toggle-label .toggle-slider {
        transform: translateX(var(--_toggle-active-translateX));
      }
    }
  }

  &-large {
    --_toggle-width: var(--dss-space-1000);
    --_toggle-height: var(--dss-space-500);
    --_toggle-button-size: var(--dss-space-400);
    --_toggle-padding: var(--dss-space-50);
    --_toggle-active-translateX: 19px;
  }
}